import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { internetProviderMenu } from '../data/menu.data';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: Subject<any[]> = new Subject<any[]>();

  constructor() {}
  public generateMenu() {
    this.menu.next(internetProviderMenu);
  }
}
