<nz-header>
  <div nz-row>
    <div nz-col nzSpan="3">
      <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="onChangeCollapse()"></i>
    </div>
    <div nz-col nzSpan="21" class="rightPart">
      <div>
        <button (click)="notificationSelectedClass = !notificationSelectedClass; profileSelectedClass=false" nz-button
          nz-popover nzType="link" [nzPopoverContent]="Content" nzPopoverPlacement="bottomRight">
          <i nz-icon nzType="bell" nzTheme="outline"></i>
        </button>
        <ng-template #Content> This is content</ng-template>
      </div>
      <div>
        <nz-avatar nzIcon="user" class="avatar" nzSrc="assets/logo-s.png" nz-button nz-dropdown [nzDropdownMenu]="menu"
          [nzPlacement]="'bottomRight'"></nz-avatar>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item> <a><i nz-icon nzType="user" nzTheme="outline"></i>My Profile</a></li>
            <li nz-menu-item> <a [routerLink]="['']"><i nz-icon nzType="setting"
                  nzTheme="outline"></i>Settings</a>
            </li>
            <li nz-menu-item (click)="logout()"><a><i nz-icon nzType="logout" nzTheme="outline"></i>Logout</a></li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</nz-header>
