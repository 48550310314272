import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, MenuService } from '@core/services';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuData = [];
  currentRoute = '';
  domesticUrl = '/main/isp/isp-main/internet-plans/domestic';
  corporateUrl = '/main/isp/isp-main/internet-plans/corporate';
  @Input() isCollapsed = true;
  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private router: Router,
  ) {
    this.currentRoute = this.router.url;
    this.menuService.menu.subscribe((menudata) => {
      this.menuData = menudata;
      this.menuData.forEach((item) => {
        item.isOpen = this.currentRoute.includes(item.route);
        // console.log('menudata', this.menuData);
      });
      // console.log('menudata', this.menuData);
    });
  }

  ngOnInit(): void {
    this.authService.authState.subscribe((status) => {
      // console.log(status);
    });
    this.menuService.generateMenu();
  }
}
