import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  authLogout: Subject<any> = new Subject<any>();
  constructor() {}
  public logOut(){
    this.authLogout.next();
  }
}
