<nz-layout class="layout">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" nzWidth="250px">
    <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
  </nz-sider>
  <nz-layout [class]="isCollapsed? 'right-layout-min': 'right-layout-full'">
    <app-header (collapsed)="onCollapseChange($event)"></app-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer>
      <app-footer></app-footer>
    </nz-footer>
  </nz-layout>
</nz-layout>
