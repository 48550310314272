import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, HttpRequestService } from '@core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = false;
  notificationSelectedClass = false;
  profileSelectedClass = false;
  userInfo = [
    {
      title: 'My profile',
    },
    {
      title: 'Settings',
    },
    {
      title: 'Logout',
    },
  ];
  @Output() collapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private httpRequestService: HttpRequestService,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit(): void {}
  onChangeCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.collapsed.emit(this.isCollapsed);
  }

  logout() {
    this.httpRequestService.request('get', 'auth/logout').subscribe(success => {
      console.log('logout Success', success);
      this.notificationService.success('Success', 'You Successfully Logged out');
    });
    this.authService.logout();
  }
}
