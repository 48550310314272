export const internetProviderMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'home',
    children: [],
  },
  {
    key: 'ispManagement',
    title: 'Isp Management',
    route: '/main/isp-management',
    icon: 'slack',
    children: [],
  },
  {
    key: 'userManagement',
    title: 'User Management',
    route: '/main/user-management',
    icon: 'user-add',
    children: [],
  },
  {
    key: 'partnerRequestManagement',
    title: 'Partner Requests',
    route: '/main/partner-request-management',
    icon: 'appstore',
    children: [],
  }
];
